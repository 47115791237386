import React from 'react'
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from 'react-google-maps'
import Layout from '../components/Layout/Layout'
import config from '../config'

const Map = withScriptjs(
  withGoogleMap(() => (
    <GoogleMap
      defaultZoom={12}
      defaultCenter={{ lat: 51.950943, lng: 7.528355 }}
    >
      <Marker position={{ lat: 51.950943, lng: 7.528355 }} />
    </GoogleMap>
  ))
)

const Anfahrt = () => {
  return (
    <Layout cols={true} title="Anfahrt">
      <div className="card">
      <Map
        googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${config.googleApiKey}&v=3.exp&libraries=geometry,drawing,places`}
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div style={{ height: `400px` }} />}
        mapElement={<div style={{ height: `100%` }} />}
      />
      </div>
      <pre>
        {`
Inna Chirkova
Schildstiege 1a
48161 Münster
Tel. 02534/5890946 oder 0176/49312927

Web: mnushi.de oder muenster-relax.de

E-mail: info@mnushi.de
`}
      </pre>
    </Layout>
  )
}

export default Anfahrt
